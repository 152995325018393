import i, { useState as p } from "react";
import { styled as a } from "styled-components";
import { Color as c, Breakpoint as n, Link as g, Typography as f, ButtonBig as k, Spacing as l, TextButton as x } from "@gleerups/syntax4";
const w = "cookie_consent";
function h(e) {
  const t = e.split(";").map((r) => r.split("=").map((u) => u.trim())).find(([r]) => r === w);
  if (!t)
    return null;
  const [, o] = t;
  return o !== "0" && o !== "1" ? (console.warn("cookie_consent: unexpected cookie value"), null) : o;
}
function $(e, t) {
  document.cookie = `cookie_consent=${e.trim()};path=/;max-age=${t};SameSite=Lax`;
}
const m = "cookie-consent:accepted";
function y() {
  return !!b();
}
function d(e) {
  if (s(`setCookieConsent, enable: ${e}`), $(v(e), 1e3 * 60 * 60 * 24 * 365), e) {
    const t = new CustomEvent(m);
    s(`setCookieConsent, dispatch event: ${m}`), window.dispatchEvent(t);
  }
}
function v(e) {
  return e ? "1" : "0";
}
function s(e) {
  var t;
  (t = window == null ? void 0 : window.config) != null && t.cookie_consent_debug && console.log(`cookie-consent: ${e}`);
}
function b() {
  return h(document.cookie);
}
const C = a.div`
  background-color: ${c.grey50};
  border-bottom: 1px solid ${c.grey200};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem 0;
  @media (${n.mediumMax}) {
    padding: 2rem 1rem;
  }
  @media (${n.smallMax}) {
    display: block;
    padding: 1rem;
  }
`, B = a(g)`
  @media (${n.smallMax}) {
    &&& {
      font-size: 14px;
    }
  }
`, E = a.div`
  font-family: ${f.fontFamily};
  font-size: 16px;
  font-weight: 400;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: left;
  width: 39rem;
  padding-right: 5rem;
  @media (${n.mediumMax}) {
    max-width: 100%;
    padding-right: 1rem;
    width: auto;
  }
  @media (${n.smallMax}) {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    padding: 0;
  }
`, S = a.div`
  @media (${n.smallMax}) {
    margin-top: 1rem;
    display: flex;
    gap: 24px;
    align-items: center;
  }
`, M = a(k)`
  margin-right: ${l.L};
  @media (${n.mediumMax}) {
    margin-bottom: ${l.S};
    margin-right: 0;
  }
  @media (${n.smallMax}) {
    margin-bottom: 0;
  }
`, T = ({ onAccept: e }) => {
  const [t, o] = p(y());
  return t ? null : /* @__PURE__ */ i.createElement(C, { role: "complementary" }, /* @__PURE__ */ i.createElement(E, null, "Gleerups skulle vilja använda en statistik-cookie med syfte att förbättra din upplevelse. Accepterar du detta? ", /* @__PURE__ */ i.createElement(B, { href: `${window.location.origin}/cookies/` }, "Läs mer om vår cookie-policy.")), /* @__PURE__ */ i.createElement(S, null, /* @__PURE__ */ i.createElement(
    M,
    {
      color: "blue",
      onClick: () => {
        d(!0), o(!0), e && e();
      }
    },
    "Acceptera alla"
  ), /* @__PURE__ */ i.createElement(
    x,
    {
      color: "blue",
      onClick: () => {
        d(!1), o(!0);
      }
    },
    "Endast nödvändiga"
  )));
};
window.config = window.config || {};
window.config.cookieBannerVersion = "4.0.22";
export {
  T as CookieBanner
};
